import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer_container">
        <section className="info_section ">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-3 ">
                <div className="info_detail">
                  <img
                    src="images/sales_mart.png"
                    style={{
                      width: "auto",
                      height: "25px",
                      background: "white",
                      padding: "4px",
                    }}
                  />
                  <br />
                  <p>
                    "Empowering Retailers with Excellence and Efficiency –
                    SalesMart"
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-2 mx-auto">
                <div className="info_link_box">
                  <h4>Links</h4>
                  <div className="info_links">
                    <a className="" href="/">
                      Home
                    </a>
                    <a className="" href="?p=about">
                      About
                    </a>
                    <a className="" href="?p=services">
                      Services
                    </a>
                    <a className="" href="?p=contact">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 ">
                <h4>Subscribe</h4>
                <form action="#">
                  <input type="text" placeholder="Enter email" />
                  <button type="submit">Subscribe</button>
                </form>
              </div>
              <div className="col-md-6 col-lg-3 mb-0 ml-auto">
                <div className="info_contact">
                  <h4>Address</h4>
                  <div className="contact_link_box">
                    <a href="">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      <span>
                        Stack Enterprises <br />
                        12/444 Paravur, Kollam 691301 <br />
                        Kerala - India
                      </span>
                    </a>
                    <a href="tel:+919037063936">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      <span>Call +919037063936 | +918139043936</span>
                    </a>
                    <a href="">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      <span>hello@salesmart.in</span>
                    </a>
                  </div>
                </div>
                <div className="info_social">
                  <a
                    href="https://www.facebook.com/profile.php?id=61556001196843"
                    target="_blank"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/salesmart.in"
                    target="_blank"
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer_section">
          <div className="container">
            <p>
              &copy; <span id="displayYear">2024</span> All Rights Reserved By
              <a href="https://salesmart.in">Stack Enterprises</a>
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
