import React, { Component } from "react";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: {},
    };
  }

  toggleReadMore = (index) => {
    this.setState((prevState) => ({
      expanded: {
        ...prevState.expanded,
        [index]: !prevState.expanded[index],
      },
    }));
  };

  render() {
    const serviceDetails = [
      {
        title: "Vast Delivery Network",
        image: "images/s1.png",
        shortText:
          "Ensure timely and efficient deliveries with our extensive network across Kerala.",
        fullText:
          "Ensure timely and efficient deliveries with our extensive network across Kerala. Our robust delivery system ensures your products reach their destination promptly and safely, enhancing your business reliability.",
      },
      {
        title: "Better Profit Margins",
        image: "images/s2.png",
        shortText:
          "Maximize your earnings with direct access to manufacturers, offering competitive prices.",
        fullText:
          "Maximize your earnings with direct access to manufacturers, offering competitive prices. Cut out the middleman and enjoy higher profit margins to boost your business growth.",
      },
      {
        title: "Quality Products",
        image: "images/s3.png",
        shortText:
          "Stock your shelves with high-quality products sourced from trusted manufacturers.",
        fullText:
          "Stock your shelves with high-quality products sourced from trusted manufacturers. Our products meet rigorous quality standards to ensure customer satisfaction and repeat business.",
      },
      {
        title: "Regular Follow-Ups",
        image: "images/s4.png",
        shortText:
          "Stay informed and supported with our consistent and personalized follow-up.",
        fullText:
          "Stay informed and supported with our consistent and personalized follow-up services. We keep you updated with the latest market trends and provide continuous support to help your business thrive.",
      },
    ];

    return (
      <>
        <section className="service_section layout_padding">
          <div className="container">
            <div className="heading_container">
              <h2>Unlock the Benefits of SalesMart</h2>
              <p>
                Discover how SalesMart can transform your business with our vast
                delivery network, better profit margins, quality products, and
                regular follow-ups. Experience efficiency, profitability, and
                superior service, all tailored to support the growth of your
                retail operations.
              </p>
            </div>
            <div className="row">
              {serviceDetails.map((service, index) => (
                <div className="col-md-6 col-lg-3" key={index}>
                  <div className="box">
                    <div className="img-box">
                      <img src={service.image} alt={service.title} />
                    </div>
                    <div className="detail-box">
                      <h5>{service.title}</h5>
                      <p>
                        {this.state.expanded[index]
                          ? service.fullText
                          : service.shortText}
                      </p>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleReadMore(index);
                        }}
                      >
                        <span>
                          {this.state.expanded[index]
                            ? "Read Less"
                            : "Read More"}
                        </span>
                        <i
                          className="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="btn-box">
              <a href="">View More</a>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Services;
