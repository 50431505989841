import React, { Component } from "react";

class AboutUs extends Component {
  state = {};
  render() {
    return (
      <>
        {this.props.full && <br />}
        <section className="about_section layout_padding">
          <div className="container  ">
            <div className="row">
              <div className="col-md-6">
                <div className="detail-box">
                  <div className="heading_container">
                    <h2>About Us</h2>
                  </div>
                  <p>
                    Welcome to SalesMart, Kerala's premier B2B platform
                    connecting retailers directly with manufacturers. Our
                    mission is to streamline the procurement process for
                    small-scale grocery shops, offering a vast range of products
                    at competitive prices with guaranteed timely delivery. As we
                    evolve, we continuously strive to empower our users with
                    innovative features like credit systems, consumer rewards,
                    and seamless UPI transactions. At SalesMart, we are
                    committed to enhancing efficiency, fostering growth, and
                    building lasting partnerships within the retail ecosystem.
                    Join us on our journey towards a smarter, more connected
                    marketplace.
                  </p>
                  <a href="">Read More</a>
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="img-box">
                  <img src="images/about-img.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.props.full && <br />}
        {this.props.full && <br />}
      </>
    );
  }
}

export default AboutUs;
