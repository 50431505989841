import React, { Component } from "react";
import constants from "../constants";

class ContactForm extends Component {
  state = {
    data: {
      name: "",
      email: "",
      phone: "",
      message: "",
      type: constants.contact_types[0],
    },
    loading: false,
    submitted: window.localStorage.getItem("submitted_contact") ?? false,
    error: null,
  };

  changeData = (where, what) => {
    var { data } = this.state;
    data[where] = what;
    this.setState({ data });
  };

  submit = () => {
    var { data } = this.state;
    if (data.name.length < 3) {
      this.setState({ error: "Invalid name" });
      return;
    }

    if (data.phone.length < 10) {
      this.setState({ error: "Invalid phone" });
      return;
    }

    this.setState({ error: null });

    this.setState({ loading: true });

    fetch(constants.url + "contacts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ submitted: true });
        window.localStorage.setItem("submitted_contact", true);
      })
      .catch((error) => {
        alert("Failed to send message. please try again.");
        this.setState({ loading: false });
      });
  };
  render() {
    return (
      <section className="contact_section layout_padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 offset-md-1">
              {this.state.submitted ? (
                <div className="message-box">
                  Your message has been sent, our executive team will soon get
                  in touch with you!
                  <br />
                  <strong>Thank you!</strong>
                </div>
              ) : (
                <>
                  <div className="form_container">
                    <div className="heading_container">
                      <h2>Request A Call back</h2>
                    </div>

                    <div>
                      <select
                        className="contact-type-select"
                        value={this.state.data.type}
                        onChange={(event) =>
                          this.changeData("type", event.target.value)
                        }
                      >
                        <option value="">Select Contact Type</option>
                        {constants.contact_types.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Full Name "
                        onChange={(event) =>
                          this.changeData("name", event.target.value)
                        }
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        placeholder="Email"
                        onChange={(event) =>
                          this.changeData("email", event.target.value)
                        }
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Phone number"
                        onChange={(event) =>
                          this.changeData("phone", event.target.value)
                        }
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        className="message-box"
                        placeholder="Message"
                        onChange={(event) =>
                          this.changeData("message", event.target.value)
                        }
                      />
                    </div>

                    {this.state.error !== null && (
                      <div className="message-box-error">
                        {this.state.error}
                      </div>
                    )}
                    <div className="d-flex ">
                      {this.state.loading ? (
                        "Loading..."
                      ) : (
                        <button onClick={this.submit}>SEND</button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-6 col-lg-7 px-0">
              <img
                src="images/contact.png"
                style={{
                  width: "60%",
                  height: "auto",
                  display: "block",
                  margin: "auto",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactForm;
