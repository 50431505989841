// const parent = "http://192.168.1.2:8000/";

var production = true;

//STAGING..
// production = false;

const parent_local = "http://localhost:8000/";
const parent_live = "https://api.salesmart.in/";

const parent = production ? parent_live : parent_local;

const constants = {
  url_parent: parent,
  url: parent + "api/",
  map_api: "AIzaSyDIRIk_y6RFE0ZGZ0_DP91sQTgCPDO-6fc", //stackentgmail
  GOOGLE_CLIENT_ID:
    "91963764005-l8h2ou6gcqfet345va4n5s69ks0f87gn.apps.googleusercontent.com",

  contact_types: ["Retailer", "Manufacturer", "JobSeeker", "Other"],
};

export default constants;
