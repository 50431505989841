import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./widgets/Headeer";
import Slider from "./widgets/Slider";
import Services from "./widgets/Services";
import AboutUs from "./widgets/AboutUs";
import CaseStudy from "./widgets/CaseStudy";
import Testimonials from "./widgets/Testimonials";
import ContactForm from "./widgets/ContactForm";
import Footer from "./widgets/Footer";

function App() {
  const [p, setP] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pValue = params.get("p");
    if (pValue) {
      setP(pValue);
    }
  }, []);

  return (
    <span className="sub_page">
      <Header page={p} />

      {p == "services" ? (
        <Services full={true} />
      ) : p == "about" ? (
        <AboutUs full />
      ) : p == "contact" ? (
        <ContactForm full />
      ) : (
        <>
          <div className="hero_area">
            {/* ADD SLIDER HERE */}
            <Slider />
          </div>
          <Services />

          <AboutUs />

          {/* <CaseStudy /> */}

          {/* <Testimonials /> */}

          <ContactForm />
        </>
      )}

      <Footer />
    </span>
  );
}

export default App;
