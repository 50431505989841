import React, { Component } from "react";

class Slider extends Component {
  render() {
    return (
      <section className="slider_section">
        <div
          id="customCarousel1"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="img-box">
                      <img src="images/slider-1.png" alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="detail-box">
                      <h1>Seamless Procurement</h1>
                      <p>
                        Experience hassle-free purchasing with direct access to
                        manufacturers, ensuring competitive prices and efficient
                        processes for small-scale grocery shops.
                      </p>
                      <div className="btn-box">
                        <a href="#" className="btn1">
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="img-box">
                      <img src="images/slider-2.png" alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="detail-box">
                      <h1>Timely Deliveries</h1>
                      <p>
                        Count on us for reliable and timely deliveries, helping
                        you maintain consistent inventory levels and meet
                        customer demands without delay.
                      </p>
                      <div className="btn-box">
                        <a href="#" className="btn1">
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="img-box">
                      <img src="images/slider-3.png" alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="detail-box">
                      <h1>Future-Ready Features</h1>
                      <p>
                        Stay ahead with our upcoming innovations like credit
                        systems, consumer rewards, and SalesMart UPI, designed
                        to enhance your business operations and growth.
                      </p>
                      <div className="btn-box">
                        <a href="#" className="btn1">
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ol className="carousel-indicators">
            <li
              data-target="#customCarousel1"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#customCarousel1" data-slide-to="1"></li>
            <li data-target="#customCarousel1" data-slide-to="2"></li>
          </ol>
        </div>
      </section>
    );
  }
}

export default Slider;
