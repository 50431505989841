import React, { Component } from "react";

class Header extends Component {
  state = {};
  render() {
    return (
      <header className="header_section">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container ">
            <a className="navbar-brand" href="https://salesmart.in/">
              <img
                src="images/sales_mart.png"
                style={{ width: "auto", height: "40px" }}
              />
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className=""> </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  ">
                <li className={`nav-item ${!this.props.page ? "active" : ""}`}>
                  <a className="nav-link" href="/">
                    Home <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li
                  className={`nav-item ${
                    this.props.page == "about" ? "active" : ""
                  }`}
                >
                  <a className="nav-link" href="?p=about">
                    {" "}
                    About
                  </a>
                </li>
                <li
                  className={`nav-item ${
                    this.props.page == "services" ? "active" : ""
                  }`}
                >
                  <a className="nav-link" href="?p=services">
                    Services
                  </a>
                </li>
                <li
                  className={`nav-item ${
                    this.props.page == "contact" ? "active" : ""
                  }`}
                >
                  <a className="nav-link" href="?p=contact">
                    Contact Us
                  </a>
                </li>
              </ul>
              <div className="quote_btn-container">
                <a href="https://store.salesmart.in" className="quote_btn">
                  E-commerce
                </a>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
